<div class="d-flex justify-content-center">
  <a class="navbar-brand d-flex flex-row align-items-center" href="#">
    <img src="../assets/images/logo_l.png" alt="" width="100" height="100" class="d-inline-block align-text-top">
    <img src="../assets/images/logo_t.png" alt="" width="150" height="50" class="d-inline-block align-text-top">
  </a>
</div>

<div id="navbar_top" class="row justify-content-center" style="background-color: #0FC5FF;">
  <div>
    <a class="nav-link" href="home">
      <h3 style="color: white;">
        Inicio
      </h3>
    </a>
  </div>
  <div>
    <a class="nav-link" href="talleres">
      <h3 style="color: white;">
        Talleres
      </h3>
    </a>
  </div>
  <div>
    <a class="nav-link" href="pildoritas">
      <h3 style="color: white;">
        Pildoritas
      </h3>
    </a>
  </div>
  <div>
    <a class="nav-link" href="contacto">
      <h3 style="color: white;">
        Contacto
      </h3>
    </a>
  </div>
  <div>
    <a class="nav-link" target="_blank" href="https://api.whatsapp.com/send?phone=525514913714&text=Hola,%20me%20podria%20brindar%20mas%20informacion%20para%20tomar%20terapia%20online.">
      <h1 class="card-text"><i style="color: green; background-color: white;" class="fab fa-whatsapp-square"></i></h1>
    </a>
  </div>
  <div>
    <a class="nav-link" target="_blank" href="https://www.facebook.com/251901586717007/posts/252243636682802/?sfnsn=scwspmo">
      <h1 class="card-text"><i style="color:blue; background-color: white;" class="fab fa-facebook-square"></i></h1>
    </a>
  </div>
</div>

