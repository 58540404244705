import {Component} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent {
    
}

document.addEventListener("DOMContentLoaded", function(){
    window.addEventListener('scroll', function() {
        if (window.scrollY > 40) {
          document.getElementById('navbar_top')?.classList.add('fixed-top');
          // add padding top to show content behind navbar
        } else {
          document.getElementById('navbar_top')?.classList.remove('fixed-top');
           // remove padding top from body
        } 
    });
  }); 
