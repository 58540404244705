import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//Rutas
import {APP_ROUTING} from './app.routes';

//Componentes
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { CuentaComponent } from './components/cuenta/cuenta.component';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { PildoritasComponent } from './components/pildoritas/pildoritas.component';
import { TalleresComponent } from './components/talleres/talleres.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ArticulosComponent,
    CuentaComponent,
    SubmenuComponent,
    ContactoComponent,
    PildoritasComponent,
    TalleresComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
