<div class="container">
    <h2>En este espacio encontraras...</h2>
    <br>
    <h4 style="text-align: justify;">Talleres motivacionales y de psicoeducación online</h4>
    <br>
    <table class="table">
        <thead class="thead-dark">
            <tr>
            <th scope="col">Taller</th>
            <!-- <th scope="col">Fecha</th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">"MI PAREJA, MI ESPEJO"</th>
            <!--<td></td>-->
          </tr>
          <tr>
            <th scope="row">"HERIDAS DE LA INFANCIA"</th>
            <!--<td></td>-->
          </tr>
        </tbody>
      </table>
</div>
