import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ArticulosComponent } from './components/articulos/articulos.component';
import { CuentaComponent } from './components/cuenta/cuenta.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { PildoritasComponent } from './components/pildoritas/pildoritas.component';
import { TalleresComponent } from './components/talleres/talleres.component';


const APP_ROUTES: Routes = [
    { path: 'home', component: HomeComponent},
    { path: 'articulos', component: ArticulosComponent},
    { path: 'cuenta', component: CuentaComponent},
    { path: 'contacto', component: ContactoComponent},
    { path: 'pildoritas', component: PildoritasComponent},
    { path: 'talleres', component: TalleresComponent},
    { path: '**', pathMatch: 'full', redirectTo: 'home'}
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);