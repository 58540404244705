<div class="container">
    <div class="row">
        <div class="col-sm d-flex justify-content-center">
            <div class="card bg-light mb-3" style="max-width: 18rem;">
                <div class="card-header bg-primary text-light" style="text-align: center;"><h5>PSICOTERAPIA ONLINE</h5></div>
                <div class="card-body">
                    <h4 class="card-text" style="text-align: center;">
                        Individual, Parejas y Familias con problemas en la comunicación y en la manera en que se relacionan.
                    </h4>
                    <div class="row">
                        <div class="col-sm d-flex justify-content-around">
                            <img src="../../../assets/images/header_2.png" height="230" width="230"/>
                        </div>
                        <!-- <div class="col-sm d-flex justify-content-around">
                            <img src="../../../assets/images/header_1.jpg" height="230" width="230"/>
                        </div> -->
                    </div>
                </div>
              </div>
            <br>
            
            <br>
        </div>
        
        <div class="col-sm d-flex justify-content-center">
            <div class="container">
                <h1 style="text-align: center; color: green;">¿Te sientes identificado?</h1>
                <!-- <br>
                <div class="col-sm d-flex justify-content-around">
                    <img src="../../../assets/images/header_3.jpg" height="300" width="300"/>
                </div> -->
                <!-- <br> -->
                <!-- <h4 style="text-align: center;">
                    Si te sientes solo, triste, decepcionado, incomprendido, desmotivado, tienes dificultad para adaptarte a los cambios, dificultad para comunicarte con los demás, sientes miedo, ansiedad, sufriste infidelidad, separación, divorcio o pérdida de algún familiar, no le encuentras sentido a la vida, te sientes inseguro, tienes estrés o inestabilidad emocional, te encuentras en una relación toxica, quieres salir de ella y no puedes.
                </h4> -->
                <!-- <h3 style="text-align: center;">
                    ¿Te sientes identificado?
                </h3> -->
                <br>
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="6000">
                    <div class="carousel-inner" style="text-align: center; height: 180px; width: 100%;">
                      <div class="carousel-item active">
                        <h5>Solo</h5>
                        <h5>Triste</h5>
                        <h5>Decepcionado</h5>
                        <h5>Incomprendido</h5>
                      </div>
                      <div class="carousel-item">
                        <h5>Desmotivado</h5>
                        <h5>Con dificultad a adaptarte a los cambios</h5>
                        <h5>Con dificultad para comunicarte con los demás</h5>
                        <h5>Sientes miedo</h5>
                      </div>
                      <div class="carousel-item">
                        <h5>Con ansiedad</h5>
                        <h5>Sufriste infidelidad</h5>
                        <h5>Separación</h5>
                        <h5>Divorcio o pérdida de algún familiar</h5>
                      </div>
                      <div class="carousel-item">
                        <!-- <h5>no le encuentras sentido a la vida, te sientes inseguro, tienes estrés o inestabilidad emocional, te encuentras en una relación toxica, quieres salir de ella y no puedes</h5> -->
                        <h5>No le encuentras sentido a la vida</h5>
                        <h5>Inseguro</h5>
                        <h5>Tienes estrés</h5>
                        <h5>Inestabilidad emocional</h5>
                      </div>
                      <div class="carousel-item">
                        <!-- <h5>no le encuentras sentido a la vida, te sientes inseguro, tienes estrés o inestabilidad emocional, te encuentras en una relación toxica, quieres salir de ella y no puedes</h5> -->
                        <h5>Te encuentras en una relación toxica, quieres salir de ella y no puedes</h5>
                      </div>
                    </div>
                    <a class="carousel-control-prev" style="justify-content: left;" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon bg-primary text-light" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" style="justify-content: right;" href="#carouselExampleControls" role="button" data-slide="next">
                      <span class="carousel-control-next-icon bg-primary text-light" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                  <br>
                <h5 style="text-align: center; color: #00A7E6; font-weight: bold">
                    Te invito a contactarme y juntos podemos darle solución a tus problemas.
                </h5>
            </div>
        </div>
    </div>    
</div>
<br>
<br>
<div>
    <div class="col">
        <div style="background-color: #8C9EFF;">
            <h1 style="text-align: center;">INTERVENCIONES</h1>
        </div>
        <div class="container">
            <h5>💊 Terapia Estratégica</h5>
            <h5>💊 Terapia Estructural</h5>
            <h5>💊 Terapia Individual</h5>
            <h5>💊 Terapia Familiar/Pareja</h5>
            <h5>💊 Terapia Transgeneracional</h5>
            <h5>💊 Terapia Cognitivo/Conductual</h5>
            <h5>💊 Terapia Breve Centrada En Soluciones</h5>
            <h5>💊 Terapia Narrativa</h5>
            <h5>💊 Terapia grupal</h5>
            <h5>💊 Programación Neuro Lingüística</h5>
        </div>
    </div>
    <div class="col">
        <div style="background-color: #8C9EFF;">
            <h1 style="text-align: center;">BENEFICIOS</h1>
        </div>
        <div class="container">
            <h5>
                💎 Bienestar desde la primera sesión
            </h5>
            <h5>
                💎 Fortalecimiento
            </h5>
            <h5>
                💎 Autocontrol
            </h5>
            <h5>
                💎 Solucion de problemas 
            </h5>
            <h5>
                💎 Autoeficacia
            </h5>
            <h5>
                💎 Felicidad
            </h5>
            <h5>
                💎 Autoestima
            </h5>
            <h5>
                💎 Trascendencia
            </h5>
            <h5>
                💎 Seguridad
            </h5>
            <h5>
                💎 Mejora la comunicación y relación con los demás
            </h5>
        </div>
    </div>
</div>
<br>
<div style="background-color: #00A7E6;color: white; font-size: 20px;">
    <div class="container">
        <div class="text-center">
            <br>
            <h2>Promoción Especial</h2>
            <h4>$499 Sesión Individual</h4>
        </div>
        <br>
        <br>
        <p style="text-align: center;">
            ¡Aprovecha nuestra <b>PROMOCION LIMITADA y agenda tu sesión online ahora mismo!</b>
        </p>
        <br>
        <a class="btn btn-success form-control" target="_blank" href="https://api.whatsapp.com/send?phone=525514913714&text=Hola,%20me%20podria%20brindar%20mas%20informacion%20para%20tomar%20terapia%20online.">
            <h5>
                Obtener Oferta Online
            </h5>
        </a>
        <br>
        <br>
        
    </div>
</div>
<br>
<br>
<br>
<br>
<div class="container">
    <h3 class="text-center">¿Como funciona la terapia online?</h3>
    <br>
    <br>
    <div class="row">
        <div class="col-sm">
            <img src="../../../assets/images/calendario.png" height="300" width="300"/>
        </div>
        <div class="col-sm">
            <h5>Elige la fecha de tu sesión</h5>
            <ul>
                <li>Atención de lunes a sabado</li>
                <li>Elige el horario que mejor se acomode a ti</li>
                <li>Recibiras un recordatorio antes de la sesión para que no la olvides</li>
                <li>Se te enviara las instrucciones para tomar la sesión en linea, para que no te preocupes de nada</li>
            </ul>
        </div>
    </div>
    <hr>
    <br>
    <br>
    <br>
    <div class="row">
        <div class="col-sm">
            <h5>Realiza tu pago</h5>
            <ul>
                <li>Cualquier Pais
                    <ul>
                        <li>Paypal</li>
                    </ul>
                </li>
                <li>México
                    <ul>
                        <li>Transferencia Bancaria</li>
                        <li>Deposito en Oxxo</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="col-sm">
            <img src="../../../assets/images/pago.png" height="300" width="300"/>
        </div>
    </div>
    <hr>
    <br>
    <br>
    <br>
    <div class="row">
        <div class="col-sm">
            <img src="../../../assets/images/conferencia.png" height="300" width="300"/>
        </div>
        <div class="col-sm">
            <h5>Preparate para tu sesión</h5>
            <ul>
                <li>Elige como quieres tomar tu sesión
                    <ul>
                        <li>Llamada telefonica</li>
                        <li>Whatsapp Videollamada</li>
                        <li>Zoom</li>
                        <li>Meet</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
<hr>
<br>
<br>
<div style="background-color: #22F2FF; text-align: center;">
    <h2>BIENESTAR PARA TRIUNFAR</h2>
</div>
<br>
<div class="text-center container">
    <a class="btn btn-success form-control" target="_blank" href="https://api.whatsapp.com/send?phone=525514913714&text=Hola,%20me%20podria%20brindar%20mas%20informacion%20para%20tomar%20terapia%20online.">
        <h5>Enviar WhatsApp</h5>
    </a>
    <br>
    <br>
    <div class="row">
        <div class="col-sm"></div>
        <div class="col-sm">
            <h5>Horario de Atención</h5>
            <div class="border border-info rounded">
                <div class="row">
                    <div class="col-sm">
                        <p>Lunes - Viernes</p>
                    </div>
                    <div class="col-sm">
                        <p>11:00 - 19:00</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <p>Sabado</p>
                    </div>
                    <div class="col-sm">
                        <p>11:00 - 18:00</p>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-sm">
                        <p>Domingo</p>
                    </div>
                    <div class="col-sm">
                        <p>11:00 - 16:00</p>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-sm"></div>
    </div>
    
</div>