import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html'
})
export class SubmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  toInicio(){
    document.getElementById("inicio")?.scrollIntoView({behavior:"smooth"});
  }
  toAcerca(){
    document.getElementById("acerca")?.scrollIntoView({behavior:"smooth"});
  }
  toObjetivo(){
      document.getElementById("objetivo")?.scrollIntoView({behavior:"smooth"});
  }
  toCandidatos(){
      document.getElementById("candidatos")?.scrollIntoView({behavior:"smooth"});
  }
  toContacto(){
      document.getElementById("contacto")?.scrollIntoView({behavior:"smooth"});
  }
}
