<div class="container">
    <div class="row">
        <div class="col-sm d-flex justify-content-around align-self-center">
            <img height="300" src="../../../assets/images/Yo2.jpeg" width="300"/>
        </div>
        <div class="col-sm">
            <br>
            <h3 style="text-align: center;">Maribel Mérida Ríos</h3>
            <br>
            <h5 style="text-align: justify;">Maestra en Psicoterapia Familiar Sistémica y Licenciada en Psicología Social comprometida con la salud mental de las personas, brindo acompañamiento psicoterapéutico a personas de 18-60 años responsables y comprometidos que solicitan ayuda profesional.</h5>
            <br>
            <h5 style="text-align: justify;">Me gusta ayudar a las personas, me apasiona mi labor y me da una inmensa felicidad poder trabajar con un enfoque de terapia que permite que sea a corto plazo y con resultados invariables, lo cual te sirve para aprovechar tu vida al maximo, te ahorra tiempo, dinero y energia. de esta manera te independizas mas pronto, puedes ir invirtiendo en ti, en lo que necesitas y sobre todo dandote lo que te mereces.</h5>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <div class="row">
        <div class="col-sm">
            <h4>Contáctame</h4>
            <br>
            <div>
                <h5>Teléfono</h5>
                <p style="color: #00A7E6;">+525514913714</p>
            </div>
            <br>
            <div>
                <h5>Correo Electronico</h5>
                <p style="color: #00A7E6;">meridamaribel429@gmail.com </p>
            </div>
            <br>
            <div class="row">
                <div class="col-sm">
                    <h5>Horario de Atención</h5>
                    <div style="background-color: #CDF1FF;">
                        <div class="row">
                            <div class="col-sm">
                                <p>Lunes - Viernes</p>
                            </div>
                            <div class="col-sm">
                                <p>11:00 - 19:00</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <p>Sabado</p>
                            </div>
                            <div class="col-sm">
                                <p>11:00 - 18:00</p>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-sm">
                                <p>Domingo</p>
                            </div>
                            <div class="col-sm">
                                <p>11:00 - 16:00</p>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-sm"></div>
            </div>
        </div>
        <div class="col-sm"></div>
        

    </div>
    
</div>
