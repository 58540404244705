<div class="container">
    <h4 style="text-align: center;">Frases motivacionales, reflexiones, antioxidantes emocionales, anécdotas y recomendaciones de lectura.</h4>
    <br>
    <div id="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                EMPATÍA <i class="fas fa-hand-point-left"></i>
              </button>
            </h5>
          </div>
      
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
                <h4>Yo soy… pero si fuera…</h4>
                <p style="text-align: justify;">
                    Opinar acerca de la vida de los demás; de sus actos u omisiones, de sus errores, de sus logros o de sus fracasos, de sus creencias, ideales o formas de vivir, resulta la mayoría de las veces algo muy sencillo y, para algunas personas, hasta satisfactorio. A menudo sale a relucir la frase “si yo fuera él o ella haría esto o aquello o dejaría de hacerlo”. A este acto se le llama juzgar. Sin embargo, ponerse en el lugar de los demás, es un proceso un tanto más complejo de los que se cree. Es un proceso que va más allá del acto mecánico de decir si yo fuera…
Para ponerse en el lugar del otro, se requiere tener cierta sensibilidad que te permita cruzar las barreras de los estereotipos y los prejuicios sociales para ver que existen diferentes formas de vivir y de pensar, pero que se es igual por la naturaleza que Dios ha puesto en el corazón del ser humano, de sentir, de amar, de equivocarse y de perdonar; es en verdad salirse de sí mismo y pensar en la vida del otro, , pero no desde la perspectiva propia, sino desde las fragilidades y fortalezas del otro. Si bien la experiencia y el aprendizaje de vida, es un proceso personal, si es posible percibir la experiencia ajena, si se está abierto de mente y de corazón. A este proceso se le llama empatía. 
La empatía es la capacidad que tiene el ser humano para conectarse a otra persona y responder adecuadamente a sus necesidades, a compartir sus sentimientos e ideas sin emitir juicio alguno. De este modo se puede decir que entre juzgar y ser empático hay un mundo de diferencia.
Talvez cuando comprendamos esto, seremos más humanos y menos jueces, podremos decir realmente Yo soy y desde los que yo soy seria y actuaria así, pero si fuera alguien más, las cosas serian, en definitiva, diferentes.
Y tú ¿eres empático o solo juzgas?
                </p>
                <div class="col-sm d-flex justify-content-around">
                    <img src="../../../assets/images/pildorita_1.jpg" height="300" width="300"/>
                </div>
            </div>
          </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  FRASE DEL DIA - FELICIDAD<i class="fas fa-hand-point-left"></i>
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                    <div class="col-sm d-flex justify-content-around">
                        <img src="../../../assets/images/pildorita_2.jpg" height="300" width="300"/>
                    </div>
                    <br>
                    <h4 style="text-align: center;">
                        "La felicidad es una decisión, atrevete a ser feliz"
                    </h4>
                </div>
            </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                LIBERTAD <i class="fas fa-hand-point-left"></i>
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="card-body">
                  <div class="col-sm d-flex justify-content-around">
                      <img src="../../../assets/images/libertad.jpg" height="400" width="400"/>
                  </div>
              </div>
          </div>
      </div>

    </div>
</div>
