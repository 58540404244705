<div class="row text-center">
    <div class="col">
        <a class="nav-link" (click)="toInicio()">Inicio</a>
    </div>
    <div class="col">
        <a class="nav-link" (click)="toAcerca()">Acerca</a>
    </div>
    <div class="col">
        <a class="nav-link" (click)="toObjetivo()">Objetivo</a>
    </div>
</div>
<div class="row text-center">
    <div class="col">
        <a class="nav-link" (click)="toCandidatos()">Candidatos</a>
    </div>
    <div class="col">
        <a class="nav-link" (click)="toContacto()">Contacto</a>
    </div>
</div>
